import React from "react";
import { createPublicView, ViewComponent } from "~/model/view/public";
import PublicTemplate from "~/components/PublicTemplate";

export const BasicNotFound: ViewComponent = () => {
    return (
        <PublicTemplate
            presentationTitle={"NOT FOUND!"}
            presentationSubtitle={"You just hit a route that doesn't exist... the sadness."}
        />
    );
};

const NotFoundPage: ViewComponent = () => {
    return (
        <PublicTemplate
            presentationTitle={"NOT FOUND!"}
            presentationSubtitle={"You just hit a route that doesn't exist... the sadness."}
        />
    );
};

export default createPublicView(NotFoundPage, {
    title: "Not Found",
});